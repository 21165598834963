import * as React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FluentProvider, makeStyles } from "@fluentui/react-components";
import Layout from "./Layout";

const queryClient = new QueryClient();

const useStyles = makeStyles({ root: { height: "100vh" } });

const App: React.FC = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <QueryClientProvider client={queryClient}>
        <FluentProvider>
          <Layout />
        </FluentProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
