import { ProjectSchema } from "@ddb/environment-context-service";
import { Store } from "@tanstack/store";
import { DdbEnvironment } from "./ddb";

// TODO: update this type once the
//       queries state object schema
//       is defined.
type Query = Object;

export interface StoreSchema {
  environment: DdbEnvironment;
  error: Error | null;
  step: number;
  project?: ProjectSchema;
  queries: Array<Query>;
}

export const STORE = new Store<StoreSchema>({
  environment: DdbEnvironment.Develop,
  step: 0,
  error: null,
  // TODO: load queries from local storage
  queries: [],
});
