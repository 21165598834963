import * as React from "react";
import { useStore } from "@tanstack/react-store";
import { Virtualizer, useStaticVirtualizerMeasure } from "@fluentui/react-components/unstable";
import { Button, makeStyles } from "@fluentui/react-components";
import { STORE } from "../../shared/store";

const useStyles = makeStyles({
  root: { display: "grid", gap: "1rem" },
  queries: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    width: "100%",
    height: "100%",
    maxHeight: "750px",
  },
  query: {
    height: "100px",
    lineHeight: "100px",
    width: "100%",
  },
});

const SavedQueries: React.FC = () => {
  const styles = useStyles();
  const queries = useStore(STORE, ({ queries }) => queries);
  const { virtualizerLength, bufferItems, bufferSize, scrollRef } = useStaticVirtualizerMeasure({
    defaultItemSize: 10,
  });

  return (
    <div className={styles.root}>
      <Button onClick={() => STORE.setState((state) => ({ ...state, step: 1 }))}>New Query</Button>

      <div aria-label="Saved Queries" className={styles.queries} role={"list"} ref={scrollRef}>
        <Virtualizer
          numItems={queries.length}
          virtualizerLength={virtualizerLength}
          bufferItems={bufferItems}
          bufferSize={bufferSize}
          itemSize={100}
        >
          {(index) => {
            return (
              <span
                role={"listitem"}
                aria-posinset={index}
                aria-setsize={queries.length}
                key={index}
                className={styles.query}
              >
                {`Node-${index}`}
              </span>
            );
          }}
        </Virtualizer>
      </div>
    </div>
  );
};

export default SavedQueries;
