import React from "react";
import { Button, Field, makeStyles, SearchBox, Select } from "@fluentui/react-components";
import { ProjectSchema } from "@ddb/environment-context-service";
import { toMap } from "../../shared/asyncIterator";
import { Batcher } from "../../shared/batch";
import { DdbEnvironment, environmentContextService, unpaginate } from "../../shared/ddb";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import { STORE } from "../../shared/store";

class ProjectIdBatcher extends Batcher<string> {
  async _getResults(environment: DdbEnvironment, keys: string[]): Promise<Map<string, string>> {
    const env = environmentContextService(environment);
    return await toMap(
      unpaginate(
        (after) => env.getProjects({ number: keys, after }),
        (data) => data.data.projects,
        (data) => data.data.paging?.cursors?.after
      ),
      (project) => project.number,
      (project) => project.project_id
    );
  }
}
const projectIdBatcher = new ProjectIdBatcher(10);

async function getProject(environment: DdbEnvironment, projectNumber: ProjectSchema["number"]): Promise<ProjectSchema> {
  try {
    const env = environmentContextService(environment);
    const ddbRefId = await projectIdBatcher.enqueue(environment, projectNumber);
    // `EnvironmentContextApiGetProjectByIdRequest.projectId` type is `object` but it should be `string`.
    const res = await env.getProjectById({ projectId: ddbRefId as unknown as object });
    return res.data.project;
  } catch {
    throw new Error(`Failed to get project data for project number ${projectNumber} in DDB (${environment}).`);
  }
}

const useStyles = makeStyles({
  form: { display: "grid", gap: "0.5rem" },
  fwField: { width: "100%" },
});

const ProjectSelect: React.FC = () => {
  const styles = useStyles();
  const environment = useStore(STORE, ({ environment }) => environment);
  const [projectNumber, setProjectNumber] = React.useState<string>("");
  const projectQuery = useQuery<ProjectSchema>({
    queryKey: ["project"],
    enabled: false,
    retry: false,
    queryFn: () =>
      getProject(environment, projectNumber)
        .then((project) => {
          STORE.setState((state) => ({ ...state, project, step: 2 }));
          return project;
        })
        .catch((error) => {
          STORE.setState((state) => ({ ...state, error }));
          throw error;
        }),
  });

  return (
    <div className={styles.form}>
      <Field className={styles.fwField}>
        <Select
          className={styles.fwField}
          value={environment}
          onChange={(e) => {
            const value = e.target.value as DdbEnvironment;
            STORE.setState((state) => ({ ...state, environment: value }));
          }}
        >
          {Object.values(DdbEnvironment).map((env) => (
            <option key={env} value={env}>
              {env}
            </option>
          ))}
        </Select>
      </Field>

      <Field className={styles.fwField}>
        <SearchBox
          className={styles.fwField}
          placeholder="Project Number"
          disabled={projectQuery.isLoading}
          onChange={(_, data) => setProjectNumber(data.value)}
        />
      </Field>

      <Button className={styles.fwField} onClick={() => projectQuery.refetch()} disabled={projectQuery.isLoading}>
        Search & Select Project
      </Button>
    </div>
  );
};

export default ProjectSelect;
