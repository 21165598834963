// TODO: update this component

import { makeStyles, Skeleton, SkeletonItem, tokens } from "@fluentui/react-components";
import * as React from "react";

const useStyles = makeStyles({
  invertedWrapper: {
    background: tokens.colorNeutralBackground1,
    display: "flex",
    padding: tokens.spacingHorizontalXL,
  },
  row: {
    display: "grid",
    gap: tokens.spacingHorizontalL,
    gridTemplateColumns: "1fr 150px 1fr",
  },
});

const SelectParamters: React.FC = () => {
  const styles = useStyles();
  return (
    <div className={styles.invertedWrapper}>
      <Skeleton className={styles.row} aria-label="Loading Content">
        <SkeletonItem size={64} shape="circle" />
        <SkeletonItem size={64} shape="rectangle" />
        <SkeletonItem size={64} shape="square" />
      </Skeleton>
    </div>
  );
};

export default SelectParamters;
